import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import TopBar from "../TopBar/TopBar";
import "./Header2.css";
import * as Hi from "react-icons/hi";
import * as Bs from "react-icons/bs";
import Hamburger from "hamburger-react";
import { AiFillCloseCircle, AiOutlineDown } from "react-icons/ai";
import { AppContext } from "../../context/AppContext";
import useAuth from "../../hooks/useAuth";

function Header2() {
  const { setIsSignUp, user } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [isUrl, setIsUrl] = useState(null);
  const [click, setClick] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [cartClick, setCartClick] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const { logoutUser } = useAuth();

  const changeNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50 ? setNavbar(true) : setNavbar(false);
    }
  };
  const profileClick = () => {};
  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  }, []);
  const handleCart = () => {
    setCartClick(!cartClick);
  };
  const handleClick = () => {
    setClick(!click);
    setShowDropdown(true);
  };
  useEffect(() => {
    setIsUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    document.addEventListener("mousedown", () => {
      setIsProfile(false);
    });
  }, []);

  const goToLogin = () => {
    setIsSignUp("login");
    navigate("/auth");
  };

  const goToSignUp = () => {
    setIsSignUp("signup");
    navigate("/auth");
  };

  const goToClassroom = () => {
    navigate("/classroom");
  };

  const handleLogout = () => {
    logoutUser();
    navigate("/");
  };
  const toggleProgram = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <>
      <TopBar />
      <div className={navbar ? "head-top scrolled" : "head-top"}>
        <div class="container d-flex">
          <div class="logo_area">
            <a href="/">
              <img src={require("../../assets/Eastman-logo1.png")} alt="" />
            </a>
          </div>
          {click ? (
            <>
              <nav>
                <ul class={click ? "nav_items active" : "nav_items"}>
                  <li>
                    <Link
                      exact={true}
                      to="/"
                      className={
                        "nav_links" + (isUrl === "/" ? " selected" : "")
                      }
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact={true}
                      to="/therapies"
                      className={
                        "nav_links" +
                        (isUrl === "/therapies" ? " selected" : "")
                      }
                    >
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact={true}
                      to="/instructor"
                      className={
                        "nav_links" +
                        (isUrl === "/instructor" ? " selected" : "")
                      }
                    >
                     Lawyer
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact={true}
                      to="/events"
                      className={
                        "nav_links" + (isUrl === "/events" ? " selected" : "")
                      }
                    >
                      Events
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact={true}
                      to="/about"
                      className={
                        "nav_links" + (isUrl === "/about" ? " selected" : "")
                      }
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link exact={true} to="/blog" className="nav_links">
                      Blog
                    </Link>
                  </li>
                </ul>
              </nav>
            </>
          ) : (
            <>
              <nav>
                <ul className={click ? "nav_items2 active" : "nav_items2"}>
                  <li>
                   
                    <Link
                      exact={true}
                      to="/"
                      className={
                        "nav_links2" + (isUrl === "/" ? " selected" : "")
                      }
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact={true}
                      to="/therapies"
                      className={
                        "nav_links2" +
                        (isUrl === "/therapies" ? " selected" : "")
                      }
                    >
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact={true}
                      to="/instructor"
                      className={
                        "nav_links2" +
                        (isUrl === "/instructor" ? " selected" : "")
                      }
                    >
                      Lawyer
                    </Link>
                  </li>

                  <li>
                    <Link
                      exact={true}
                      to="/about"
                      className={
                        "nav_links2" + (isUrl === "/about" ? " selected" : "")
                      }
                    >
                      About Us
                    </Link>
                  </li>
                </ul>
              </nav>
            </>
          )}
          {/* <div class="search-box">
            <input type="search" name="serach_box" id="" />
            <i>
              <Bs.BsSearch />
            </i>
          </div> */}

          <div class="profile">
            <div class="avatar" onClick={() => setIsProfile(!isProfile)}></div>

            <div
              class={isProfile ? "profile_details show" : "profile_details "}
            >
              {!user ? (
                <>
                  {" "}
                  <a onClick={() => goToLogin()}>Login</a>
                  <hr />
                  <a onClick={() => goToSignUp()}>Signup</a>
                </>
              ) : (
                <>
                  <a>Profile</a>
                  <hr />
                  <a onClick={() => goToClassroom()}>Live Session</a>
                  <hr />
                  <a onClick={() => handleLogout()}>Logout</a>
                </>
              )}
            </div>
          </div>
          <div class="nav-icon" onClick={handleClick}>
            <Hamburger />
          </div>
          {/* <div class="cart">
            <button class="cart_btn" onClick={handleCart}>
              <Hi.HiShoppingBag />
            </button>
            <div class={cartClick ? "cart_menu show" : "cart_menu "}>
              <p>No items in the cart</p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Header2;
