import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./About.css";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { motion } from "framer-motion";
import Bottombar from "../../components/BottomBar/BottomBar";
import Transitions from "../../Transition";
import * as Md from "react-icons/md";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Popup from "../../components/Popup/Popup";

function About() {
  const [toggleState, setToggleState] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const [videoUp, setVideoUp] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const accordionChange = (isExpanded, panel) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Transitions>
        <Popup trigger={videoUp} setTrigger={setVideoUp} />
        <header>
          <Header />
        </header>
        <main>
          <section class="needToKnow">
            <div class="bgg"></div>
            <div class="container-sm">
              <h1
                className="page_head_title text-center"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                About Us
              </h1>
              <div class="row">
                <div
                  class="col-lg-6 col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div class="know_img">
                    <img
                      src={require("../../assets/Eastman/Lawyers/court.jpg")}
                      alt=""
                    />
                  </div>
                </div>
                {/* col End */}
                <div
                  class="col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <div class="know_cont">
                    <p class="sub_head"> Eastman Solicitors & Notary</p>
                    <h1>Our Story</h1>
                    <p className="know_para">
                      Our law firm was established in the year 2011 by Mr.
                      Sajeev Kooran as a sole practitioner. Mr.Kooran has been
                      in the Industry for over 13 years, starting his career
                      back in the year 2004 and is backed up by years of
                      outstanding experience in the areas of Immigration,
                      Conveyancing and Family laws. His multiple legal skills
                      and excellent track record has made him one of the most
                      accomplished and popular lawyers in and around the area
                      where we are located. This has inspired him to establish
                      this firm in London, the city is known as the mecca of
                      opportunities and the hub of investments and economic
                      activities. Since established the firm has extended its
                      reputation throughout the UK and abroad and now the
                      practice has developed to its present stage.
                    </p>
                    <h1>What We Do?</h1>
                    <p>
                      We are a registered limited company under registration
                      number: 9599419, we trade in the market as a solicitor
                      firm and are regulated by SRA. Our wealth of services
                      includes ..
                      <ul className="m-3 colors">
                        <li>Conveyancing of Properties</li>
                        <li>Family related disputes</li>
                        <li>Immigration</li>
                        <li>Wills and Probates </li>
                      </ul>
                      This firm is CQS accredited and specialised to carry out
                      residential conveyancing. We are also well versed in
                      handling matters related to the NMC, court of protection,
                      creating and executing wills and probates, as well as
                      handling liquor licensing applications.
                    </p>
                  </div>
                </div>
                {/* col End */}
              </div>
            </div>
          </section>

          <section class="trial2">
            <div class="trial2_cont">
              <img
                className="abt_bgg"
                src={require("../../assets/about/background_img.jpg")}
              />
              <div class="container">
                <div class="trial2_container">
                  <h1>Philosophy</h1>
                  <p className="para-philo">
                    We have made sure our legal team is made up of professionals
                    who are well versed in areas of immigration, commercial and
                    residential conveyancing and family laws. Each lawyer is
                    backed up by years of experience in their specific fields,
                    making them a good choice to hire for people who find
                    themselves in legal trouble. We believe our clients are the
                    reason we are successful every step of way, we value your
                    opinions. We know that our clients want us to strive harder
                    and break barriers of success, which is why we give value to
                    their opinions and have built up our reputation around the
                    world on the back of recommendations and word of mouth. We
                    make sure our clients are offered, professional, friendly
                    and high quality service, each and every time.
                  </p>
                  {/* <button class="trial_btn">
                    Start my free month <Bs.BsChevronRight />
                  </button> */}
                </div>
              </div>
            </div>
            <Bottombar />
          </section>
          <section class="who_we_are">
            <div class="container">
              <div class="bloc-tabs" data-aos="zoom-out">
                <motion.div
                  class={toggleState === 1 ? "tabs active-tab" : "tabs"}
                  onClick={() => toggleTab(1)}
                >
                  Our Team
                </motion.div>
                <motion.div
                  class={toggleState === 2 ? "tabs active-tab" : "tabs"}
                  onClick={() => toggleTab(2)}
                >
                  CQS Accredited Solicitors
                </motion.div>
              </div>
              <div
                class="content-tabs"
                data-aos="zoom-out"
                data-aos-duration="1000"
              >
                <div
                  class={
                    toggleState === 1 ? "content  active-content" : "content"
                  }
                >
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="tabss_cont">
                          <h2>Eastman Solicitors & Notary</h2>

                          <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div class="card pro_card1 mt-3">
                                <div className="profile-image">
                                  <img
                                    src={require("../../assets/Eastman/Lawyers/Gemma-1-300x300.png")}
                                  />
                                  <div className="overlay1">
                                    <span className="vline"></span>
                                    <span className="vline-bottom"></span>
                                  </div>
                                </div>
                                <div class="card-body proCard1">
                                  <div className="text-center box-name">
                                    <p className="name-data">
                                      Ms. Gemma Mascarenhas
                                      <br />
                                    </p>
                                    <small>Director (Solicitor/Mediator)</small>
                                  </div>

                                  <ul className="practice">
                                    <h5 className="colors">Area of Practice</h5>
                                    <li>Family Law</li>
                                    <li>Landlord & Tenant</li>
                                    <li>Will & Probate</li>

                                    <li className="email">
                                      <small>
                                        Email:gemma@primarcsolicitors.com
                                      </small>
                                    </li>
                                    <li className="bio">
                                      {" "}
                                      <ReactReadMoreReadLess
                                        charLimit={70}
                                        className="read-more"
                                        readMoreText={"Read more "}
                                        readLessText={"Read less ▲"}
                                      >
                                        Ms. Gemma Mascarenhas brings over 15
                                        years of legal expertise to Eastman
                                        Solicitors, where she serves as the
                                        Director and leads the Family Law,
                                        Landlord & Tenant, and Will & Probate
                                        departments. As a qualified solicitor
                                        and accredited mediator, Ms. Mascarenhas
                                        is committed to providing comprehensive
                                        legal support and dispute resolution
                                        services to our clients. With a
                                        compassionate approach and a deep
                                        understanding of the complexities within
                                        these areas of law, Ms. Mascarenhas
                                        ensures that every client receives
                                        personalized attention and effective
                                        solutions tailored to their unique
                                        circumstances. Beyond her professional
                                        achievements, Ms. Mascarenhas is
                                        actively involved in community
                                        initiatives aimed at promoting access to
                                        justice and legal education. Her
                                        dedication to excellence and client
                                        satisfaction truly sets the standard at
                                        Eastman Solicitors.
                                      </ReactReadMoreReadLess>
                                    </li>

                                    {/* <p className="bio">
                                      <b>Bio:</b>
                                      Ms. Gemma Mascarenhas brings over 15 years
                                      of legal expertise to Eastman Solicitors,
                                      where she serves as the Director and leads
                                      the Family Law, Landlord & Tenant, and
                                      Will & Probate departments. As a qualified
                                      solicitor and accredited mediator, Ms.
                                      Mascarenhas is committed to providing
                                      comprehensive legal support and dispute
                                      resolution services to our clients. With a
                                      compassionate approach and a deep
                                      understanding of the complexities within
                                      these areas of law, Ms. Mascarenhas
                                      ensures that every client receives
                                      personalized attention and effective
                                      solutions tailored to their unique
                                      circumstances. Beyond her professional
                                      achievements, Ms. Mascarenhas is actively
                                      involved in community initiatives aimed at
                                      promoting access to justice and legal
                                      education. Her dedication to excellence
                                      and client satisfaction truly sets the
                                      standard at Eastman Solicitors.
                                    </p> */}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div class="card pro_card1 mt-3">
                                <div className="profile-image">
                                  <img
                                    src={require("../../assets/Eastman/Lawyers/MrKooran--300x300.png")}
                                  />
                                  <div className="overlay1">
                                    <span className="vline"></span>
                                    <span className="vline-bottom"></span>
                                  </div>
                                </div>
                                <div class="card-body proCard1">
                                  <div className="text-center box-name">
                                    <p className="name-data">
                                      Mr. Sajeev Kooran
                                      <br />
                                    </p>
                                    <small>Director</small>
                                  </div>

                                  <ul className="practice">
                                    <h5 className="colors">Area of Practice</h5>
                                    <li>Family Law</li>
                                    <li>Immigration</li>
                                    <li>Property law</li>
                                    <li>
                                      <small>
                                        Email:sk@eastmansolicitors.com
                                        {/* Email:sk@primarcsolicitors.com */}
                                      </small>
                                      {/* <p className="bio">
                                        <b>Bio:</b>
                                        Mr. Sajeev Kooran serves as a Director
                                        at Eastman Solicitors, bringing a wealth
                                        of experience and expertise to the
                                        firm's Family Law, Immigration, and
                                        Property Law departments. With a focus
                                        on providing comprehensive legal
                                        solutions, Mr. Kooran is dedicated to
                                        guiding clients through the complexities
                                        of their legal matters with integrity
                                        and professionalism. His extensive
                                        knowledge and strategic approach ensure
                                        that clients receive tailored advice and
                                        representation to achieve their desired
                                        outcomes. In addition to his role at
                                        Eastman Solicitors, Mr. Kooran is
                                        actively involved in legal advocacy and
                                        community outreach initiatives,
                                        demonstrating his commitment to serving
                                        both clients and the broader community.
                                      </p> */}
                                    </li>
                                    <li className="bio">
                                      {" "}
                                      <ReactReadMoreReadLess
                                        charLimit={50}
                                        className="read-more"
                                        readMoreText={"Read more "}
                                        readLessText={"Read less ▲"}
                                      >
                                        Mr. Sajeev Kooran serves as a Director
                                        at Eastman Solicitors, bringing a wealth
                                        of experience and expertise to the
                                        firm's Family Law, Immigration, and
                                        Property Law departments. With a focus
                                        on providing comprehensive legal
                                        solutions, Mr. Kooran is dedicated to
                                        guiding clients through the complexities
                                        of their legal matters with integrity
                                        and professionalism. His extensive
                                        knowledge and strategic approach ensure
                                        that clients receive tailored advice and
                                        representation to achieve their desired
                                        outcomes. In addition to his role at
                                        Eastman Solicitors, Mr. Kooran is
                                        actively involved in legal advocacy and
                                        community outreach initiatives,
                                        demonstrating his commitment to serving
                                        both clients and the broader community.
                                      </ReactReadMoreReadLess>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div class="card pro_card1 mt-3">
                                <div className="profile-image">
                                  <img
                                    src={require("../../assets/Eastman/Lawyers/Satnam--300x300.png")}
                                  />
                                  <div className="overlay1">
                                    <span className="vline"></span>
                                    <span className="vline-bottom"></span>
                                  </div>
                                </div>
                                <div class="card-body proCard1">
                                  <div className="text-center box-name">
                                    <p className="name-data">
                                      Satnam Singh <br />
                                    </p>
                                    <small>Director / Notary Public</small>
                                  </div>

                                  <ul className="practice">
                                    <h5 className="colors">Area of Practice</h5>
                                    <li>Conveyancing</li>
                                    <li>Family Law</li>
                                    <li>Immigration</li>
                                    <li>Will and probate </li>

                                    <li>
                                      <small>
                                        {/* Email:satnam@primarcsolicitor.com */}
                                        Email :ssbhogal@eastmansolicitor.com
                                      </small>
                                    </li>
                                    <li className="bio">
                                      {" "}
                                      <ReactReadMoreReadLess
                                        charLimit={50}
                                        className="read-more"
                                        readMoreText={"Read more "}
                                        readLessText={"Read less ▲"}
                                      >
                                        Satnam Singh is a Notary public who
                                        holds the position of Director at
                                        Eastman Solicitors, where he specializes
                                        in Conveyancing, Family Law,
                                        Immigration, and Will & Probate matters.
                                        With a passion for providing
                                        high-quality legal services, Mr. Singh
                                        is committed to guiding clients through
                                        the complexities of property
                                        transactions, family disputes,
                                        immigration issues, and estate planning
                                        with care and expertise. His extensive
                                        experience and dedication to client
                                        satisfaction ensure that individuals
                                        receive tailored solutions that meet
                                        their unique needs and objectives.
                                        Beyond his professional endeavours, Mr.
                                        Singh is actively involved in community
                                        initiatives, reflecting his commitment
                                        to making a positive impact both within
                                        and beyond the legal profession.
                                      </ReactReadMoreReadLess>
                                    </li>
                                    {/* <p className="bio">
                                      <b>Bio:</b>
                                      Satnam Singh is a Notary public who holds
                                      the position of Director at Eastman
                                      Solicitors, where he specializes in
                                      Conveyancing, Family Law, Immigration, and
                                      Will & Probate matters. With a passion for
                                      providing high-quality legal services, Mr.
                                      Singh is committed to guiding clients
                                      through the complexities of property
                                      transactions, family disputes, immigration
                                      issues, and estate planning with care and
                                      expertise. His extensive experience and
                                      dedication to client satisfaction ensure
                                      that individuals receive tailored
                                      solutions that meet their unique needs and
                                      objectives. Beyond his professional
                                      endeavours, Mr. Singh is actively involved
                                      in community initiatives, reflecting his
                                      commitment to making a positive impact
                                      both within and beyond the legal
                                      profession.
                                    </p> */}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div class="card pro_card1 mt-3">
                                <div className="profile-image">
                                  <img
                                    src={require("../../assets/Eastman/Lawyers/Johel--300x300.png")}
                                  />
                                  <div className="overlay1">
                                    <span className="vline"></span>
                                    <span className="vline-bottom"></span>
                                  </div>
                                </div>
                                <div class="card-body proCard1">
                                  <div className="text-center box-name">
                                    <p className="name-data">
                                      Mr Juhel Miah <br />
                                    </p>
                                    <small>Solicitor</small>
                                  </div>

                                  <ul className="practice">
                                    <h5 className="colors">Area of Practice</h5>
                                    <li>Commercial Finance</li>
                                    <li>Landlord & Tenant</li>
                                    <li>Leasehold and Company matters</li>
                                    <li>General Litigation</li>

                                    <li>
                                      <small>
                                        Email:jmiah@primarcsolicitors.com
                                      </small>
                                    </li>
                                    <li className="bio">
                                      {" "}
                                      <ReactReadMoreReadLess
                                        charLimit={50}
                                        className="read-more"
                                        readMoreText={"Read more "}
                                        readLessText={"Read less ▲"}
                                      >
                                        Mr. Juhel Miah serves as a Solicitor at
                                        Eastman Solicitors, specializing in
                                        Commercial Finance, Landlord & Tenant,
                                        General Litigation and Leasehold and
                                        Company Matters. With a focus on
                                        providing expert legal advice and
                                        representation, Mr. Miah assists clients
                                        in navigating complex legal issues
                                        related to commercial transactions,
                                        property agreements, and Mr. Juhel Miah
                                        serves as a Solicitor at Eastman
                                        Solicitors, specializing in Commercial
                                        Finance, Landlord & Tenant, General
                                        Litigation and Leasehold and Company
                                        Matters. With a focus on providing
                                        expert legal advice and representation,
                                        Mr. Miah assists clients in navigating
                                        complex legal issues related to
                                        commercial transactions, property
                                        agreements, and Mr. Juhel Miah serves as
                                        a Solicitor at Eastman Solicitors,
                                        specializing in Commercial Finance,
                                        Landlord & Tenant, General Litigation
                                        and Leasehold and Company Matters. With
                                        a focus on providing expert legal advice
                                        and representation, Mr. Miah assists
                                        clients in navigating complex legal
                                        issues related to commercial
                                        transactions, property agreements, and
                                        corporate governance. His in-depth
                                        knowledge and practical approach ensure
                                        that clients receive comprehensive
                                        solutions tailored to their specific
                                        needs and objectives. Committed to
                                        delivering exceptional service, Mr. Miah
                                        prioritizes clear communication and
                                        client satisfaction in every aspect of
                                        his practice
                                      </ReactReadMoreReadLess>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div class="card pro_card1 mt-3">
                                <div className="profile-image">
                                  <img
                                    src={require("../../assets/Eastman/Lawyers/Henry--300x300.png")}
                                  />
                                  <div className="overlay1">
                                    <span className="vline"></span>
                                    <span className="vline-bottom"></span>
                                  </div>
                                </div>
                                <div class="card-body proCard1">
                                  <div className="text-center box-name">
                                    <p className="name-data">
                                      Mr. Henry Cheng <br />
                                    </p>
                                    <small>Solicitor</small>
                                  </div>

                                  <ul className="practice">
                                    <h5 className="colors">Area of Practice</h5>
                                    <li>Family Law</li>
                                    <li>Property Law</li>
                                    <li>Private Client</li>
                                    <li>Immigration</li>
                                    <li>Civil litigation</li>
                                    <li>Landlord and Tenant</li>
                                    {/* <li>Dispute Resolution</li> */}

                                    <li>
                                      <small>
                                        Email:hcheng@eastmansolicitors.com
                                        {/* Email:henry@primarcsolicitors.com */}
                                      </small>
                                    </li>
                                    <li className="bio">
                                      {" "}
                                      <ReactReadMoreReadLess
                                        charLimit={50}
                                        className="read-more"
                                        readMoreText={"Read more "}
                                        readLessText={"Read less ▲"}
                                      >
                                        Henry cheng began his legal career at
                                        Primarc Solicitor in 2014 as a
                                        caseworker after graduating from the
                                        City Law School with Commendation. He
                                        quickly established himself as a
                                        valuable member of the team before
                                        furthering his experience at a Legal 500
                                        firm, where he served as a senior
                                        caseworker. In 2016, Henry returned to
                                        Primarc Solicitor as a trainee
                                        solicitor, demonstrating his commitment
                                        to the firm's ethos and values. He
                                        successfully qualified as a solicitor in
                                        2017, bringing a wealth of experience
                                        and expertise across various areas of
                                        law. With a diverse practice
                                        encompassing Family Law, Property Law,
                                        Landlord and Tenant matters, Private
                                        Client services, Immigration law, and
                                        Civil Litigation, Henry is dedicated to
                                        providing comprehensive legal solutions
                                        to clients. His strong analytical
                                        skills, coupled with a client-focused
                                        approach, ensure that individuals
                                        receive tailored advice and
                                        representation to address their specific
                                        needs and achieve favorable outcomes.
                                        Outside of his professional pursuits,
                                        Henry enjoys indulging in his hobbies,
                                        which include reading, watching movies,
                                        and listening to music. His multifaceted
                                        interests reflect his vibrant
                                        personality and well-rounded approach to
                                        life.
                                      </ReactReadMoreReadLess>
                                    </li>
                                    {/* <p className="bio">
                                      <b>Bio:</b>
                                      Henry Chang began his legal career at
                                      Primarc Solicitor in 2014 as a caseworker
                                      after graduating from the City Law School
                                      with Commendation. He quickly established
                                      himself as a valuable member of the team
                                      before furthering his experience at a
                                      Legal 500 firm, where he served as a
                                      senior caseworker. In 2016, Henry returned
                                      to Primarc Solicitor as a trainee
                                      solicitor, demonstrating his commitment to
                                      the firm's ethos and values. He
                                      successfully qualified as a solicitor in
                                      2017, bringing a wealth of experience and
                                      expertise across various areas of law.
                                      With a diverse practice encompassing
                                      Family Law, Property Law, Landlord and
                                      Tenant matters, Private Client services,
                                      Immigration law, and Civil Litigation,
                                      Henry is dedicated to providing
                                      comprehensive legal solutions to clients.
                                      His strong analytical skills, coupled with
                                      a client-focused approach, ensure that
                                      individuals receive tailored advice and
                                      representation to address their specific
                                      needs and achieve favorable outcomes.
                                      Outside of his professional pursuits,
                                      Henry enjoys indulging in his hobbies,
                                      which include reading, watching movies,
                                      and listening to music. His multifaceted
                                      interests reflect his vibrant personality
                                      and well-rounded approach to life.
                                    </p> */}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div class="card pro_card1 mt-3">
                                <div className="profile-image">
                                  <img
                                    src={require("../../assets/Eastman/Lawyers/Leelu--300x300.png")}
                                  />
                                  <div className="overlay">
                                    <span className="vline"></span>
                                    <span className="vline-bottom"></span>
                                  </div>
                                </div>
                                <div class="card-body proCard1">
                                  <div className="text-center box-name">
                                    <p className="name-data">
                                      Mr. Leeladharan Bhandary <br />
                                    </p>
                                    <small>Director</small>
                                  </div>

                                  <ul className="practice">
                                    <h5 className="colors">Area of Practice</h5>
                                    <li>Asylum Applications</li>
                                    <li>Immigration</li>
                                    <li>Judicial Reviews</li>
                                    <li>
                                      <small>
                                        Email:leelu@primarcsolicitors.com
                                      </small>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-tabs">
                <div
                  class={
                    toggleState === 2 ? "content  active-content" : "content"
                  }
                >
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <h2>CQS Client Charter</h2>
                        <p style={{ height: "auto" }}>
                          NThis scheme’s logo is your guarantee that our
                          practice will provide you with a professional and
                          quality conveyancing service in accordance with the
                          scheme rules.
                        </p>
                        <div class="tab_img">
                          <img
                            src={require("../../assets/Eastman/pexels-ekaterina-bolovtsova-6077123.jpg")}
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tabss_cont">
                          <h5>What you can expect from us: </h5>
                          <ol>
                            <li>
                              when you contact us to discuss your sale or
                              purchase we will explain clearly the steps in the
                              process and what you can expect from your
                              solicitor
                            </li>
                            <li>we will tell you what the costs will be</li>
                            <li>
                              we will keep you informed of progress in your sale
                              or purchase
                            </li>
                            <li>
                              we will work in line with the quality standards of
                              the Law Society’s CQS.
                            </li>
                          </ol>
                          <h5>We Will : </h5>
                          <ul>
                            <li>treat you fairly</li>
                            <li>be polite and professional</li>
                            <li>respond promptly to your enquiries</li>
                            <li>
                              tell you about any problems as soon as we are
                              aware of them
                            </li>
                            <li>ask for your feedback on our service</li>
                          </ul>
                          <p>
                            Being CQS accredited solicitors is both an honour
                            and responsibility. We strive to rise above the
                            mandatory requirements prescribed by CQS.
                            Surprisingly, a recent study has shown that a large
                            number of solicitor firms have not yet got their
                            accreditation sorted. We certainly find value in
                            being accredited. CQS accreditation has given us a
                            proper structure and guidelines when disposing our
                            services.
                          </p>
                          <p>
                            To know more about the Conveyancing Services we
                            provide, check out Conveyancing Page for more
                            information. Feel free to contact our office to make
                            a formal complaint or if you want to more
                            information on any aspect of our services delivery.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-tabs"></div>
            </div>
          </section>

          <section class="faq">
            <div class="container">
              <div class="faq_cont">
                <p>QUESTION & ANSWERS (Q&A)</p>
                <h1>Frequently Asked Questions (FAQ)</h1>

                <div
                  class="accord_area"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={(event, isExpanded) =>
                      accordionChange(isExpanded, "panel1")
                    }
                    className="accord"
                  >
                    <AccordionSummary
                      className="panel1_header panel__"
                      aria-label="panel1_header"
                      expandIcon={<Md.MdOutlineUnfoldMore />}
                    >
                      <h1 className="accord_title">
                        We have a team of experienced teachers
                      </h1>
                    </AccordionSummary>
                    <AccordionDetails className="accord_details">
                      <p className="accord_p">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Aliquid blanditiis harum a, corrupti veritatis
                        molestias eligendi mollitia quasi labore sed, velit
                        saepe fugit aperiam officiis quos sequi voluptates.
                        Eveniet, numquam?
                      </p>
                      <h2 className="accord_sub_h">
                        A transformational experience that will shape your
                        unique profile and help you launch your professional
                        career.
                      </h2>
                      <p className="accord_p">
                        With the dawn of the twentieth century, the Company
                        handed over the Institute to the University of London.
                        It was re-christened Goldsmiths College and the modern
                        era of Goldsmiths had begun.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  {/* accordion end */}

                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={(event, isExpanded) =>
                      accordionChange(isExpanded, "panel2")
                    }
                    className="accord"
                  >
                    <AccordionSummary
                      className="panel2_header panel__"
                      aria-label="panel2_header"
                      expandIcon={<Md.MdOutlineUnfoldMore />}
                    >
                      <h1 className="accord_title">
                        We offer academic excellence combined with innovative
                        thinking
                      </h1>
                    </AccordionSummary>
                    <AccordionDetails className="accord_details">
                      <p className="accord_p">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Aliquid blanditiis harum a, corrupti veritatis
                        molestias eligendi mollitia quasi labore sed, velit
                        saepe fugit aperiam officiis quos sequi voluptates.
                        Eveniet, numquam?
                      </p>
                      <h2 className="accord_sub_h">
                        A transformational experience that will shape your
                        unique profile and help you launch your professional
                        career.
                      </h2>
                      <p className="accord_p">
                        With the dawn of the twentieth century, the Company
                        handed over the Institute to the University of London.
                        It was re-christened Goldsmiths College and the modern
                        era of Goldsmiths had begun.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  {/* accordion end */}

                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={(event, isExpanded) =>
                      accordionChange(isExpanded, "panel3")
                    }
                    className="accord"
                  >
                    <AccordionSummary
                      className="panel3_header panel__"
                      aria-label="panel3_header"
                      expandIcon={<Md.MdOutlineUnfoldMore />}
                    >
                      <h1 className="accord_title">
                        We challenge you to discover your potential and support
                        you in fulfilling
                      </h1>
                    </AccordionSummary>
                    <AccordionDetails className="accord_details">
                      <p className="accord_p">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Aliquid blanditiis harum a, corrupti veritatis
                        molestias eligendi mollitia quasi labore sed, velit
                        saepe fugit aperiam officiis quos sequi voluptates.
                        Eveniet, numquam?
                      </p>
                      <h2 className="accord_sub_h">
                        A transformational experience that will shape your
                        unique profile and help you launch your professional
                        career.
                      </h2>
                      <p className="accord_p">
                        With the dawn of the twentieth century, the Company
                        handed over the Institute to the University of London.
                        It was re-christened Goldsmiths College and the modern
                        era of Goldsmiths had begun.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  {/* accordion end */}

                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={(event, isExpanded) =>
                      accordionChange(isExpanded, "panel4")
                    }
                    className="accord"
                  >
                    <AccordionSummary
                      className="panel4_header panel__"
                      aria-label="panel4_header"
                      expandIcon={<Md.MdOutlineUnfoldMore />}
                    >
                      <h1 className="accord_title">
                        Careers Service - finding work during and after studying
                      </h1>
                    </AccordionSummary>
                    <AccordionDetails className="accord_details">
                      <p className="accord_p">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Aliquid blanditiis harum a, corrupti veritatis
                        molestias eligendi mollitia quasi labore sed, velit
                        saepe fugit aperiam officiis quos sequi voluptates.
                        Eveniet, numquam?
                      </p>
                      <h2 className="accord_sub_h">
                        A transformational experience that will shape your
                        unique profile and help you launch your professional
                        career.
                      </h2>
                      <p className="accord_p">
                        With the dawn of the twentieth century, the Company
                        handed over the Institute to the University of London.
                        It was re-christened Goldsmiths College and the modern
                        era of Goldsmiths had begun.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  {/* accordion end */}

                  <Accordion
                    expanded={expanded === "panel5"}
                    onChange={(event, isExpanded) =>
                      accordionChange(isExpanded, "panel5")
                    }
                    className="accord"
                  >
                    <AccordionSummary
                      className="panel5_header panel__"
                      aria-label="panel5_header"
                      expandIcon={<Md.MdOutlineUnfoldMore />}
                    >
                      <h1 className="accord_title">
                        We challenge you to discover your potential and support
                        you in fulfilling
                      </h1>
                    </AccordionSummary>
                    <AccordionDetails className="accord_details">
                      <p className="accord_p">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Aliquid blanditiis harum a, corrupti veritatis
                        molestias eligendi mollitia quasi labore sed, velit
                        saepe fugit aperiam officiis quos sequi voluptates.
                        Eveniet, numquam?
                      </p>
                      <h2 className="accord_sub_h">
                        A transformational experience that will shape your
                        unique profile and help you launch your professional
                        career.
                      </h2>
                      <p className="accord_p">
                        With the dawn of the twentieth century, the Company
                        handed over the Institute to the University of London.
                        It was re-christened Goldsmiths College and the modern
                        era of Goldsmiths had begun.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  {/* accordion end */}
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* Main End */}
        <footer>
          <Footer />
        </footer>
        {/* Footer End */}
      </Transitions>
    </>
  );
}

export default About;
