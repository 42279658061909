import React from "react";
import "./BottomBar.css";

// icon packs
import * as Fa from "react-icons/fa";
import * as Md from "react-icons/md";
import * as Gi from "react-icons/gi";

function BottomBar() {
  return (
    <>
      {/*  */}
    </>
  );
}

export default BottomBar;
