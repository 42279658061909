import { useState } from "react";
import { APIEndpoints } from "../constants/ApiEndPoints";
import BaseClient from "../helpers/base_client";

import { loadStripe } from "@stripe/stripe-js";

export default function usePayment() {
  const [loading, setLoading] = useState(false);
  const [isPaymentElementLoaded, setIsPaymentElementLoaded] = useState(false);
  const [stripePaymentClientSecret, setStripeClientSecret] = useState(null);
  const initialValue = {
    fullName: "",
    email: "",
    state: "",
    postcode: "",
  };

  const [billingAddress, setBillingAddress] = useState(initialValue);
  const [paymentStatus, setPaymentStatus] = useState(null);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const options = {
    appearance: {
      theme: "default",
    },
    clientSecret: stripePaymentClientSecret,
  };

  const createPaymentIntent = async (payload, { onSuccess, onFailed }) => {
    try {
      setLoading(true);
      await BaseClient.post(APIEndpoints.createPaymentIntent, payload, {
        onSuccess: (data) => {
          if (data.error == false && data.clientSecret) {
            onSuccess(data.clientSecret);
          }
        },
        onFailed: (error) => {
          console.error("error", error);
        },
        authentication: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const onPaymentElementReady = () => {
    setIsPaymentElementLoaded(true);
  };

  const confirmPayment = async ({
    stripe,
    elements,
    paymentSuccess,
    paymentFailure,
  }) => {
    try {
      if (!stripe || !elements) return;
      setLoading(true);
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (paymentIntent && paymentIntent.status === "succeeded") {
        // console.log("Payment Success", paymentIntent);
        await paymentSuccess(paymentIntent);
        setPaymentStatus(true);
      } else if (error) {
        await paymentFailure(error);
        setPaymentStatus(false);
      }
    } finally {
      setLoading(false);
      setPaymentStatus(null);
    }
  };
  const iniateNMIPayment = async (payload, { onSuccess, onFailed }) => {
    try {
      setLoading(true);

      await BaseClient.post(APIEndpoints.createNMIPayment, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    createPaymentIntent,
    stripePromise,
    loading,
    onPaymentElementReady,
    isPaymentElementLoaded,
    options,
    stripePaymentClientSecret,
    setStripeClientSecret,
    confirmPayment,
    setIsPaymentElementLoaded,
    billingAddress,
    setBillingAddress,
    paymentStatus,
    iniateNMIPayment,
  };
}
