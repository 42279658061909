import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import TherapyConfirmation from "./TherapyConfirmation";
import Checkout from "../Checkout/Checkout";
import * as Gr from "react-icons/gr";
import * as Im from "react-icons/im";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { message } from "antd";
import { AppContext } from "../../context/AppContext";
import SuccessPage from "../resultPage/SuccessPage";
import ErrorPage from "../resultPage/ErrorPage";

function TherapyStepper() {
  const navigate = useNavigate();
  const steps = ["Select a Slot", "Checkout"];

  const {
    selectedScheduleSlot,
    activeStep,
    setActiveStep,
    paymentStatus,
    selectedDateSchedules,
    disableButton,
  } = useContext(AppContext);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const confirmAction = () => {
    confirmAlert({
      title: "Confirm Action",
      message: "Are your sure?",
      buttons: [
        { label: "Yes", onClick: () => navigate("/therapies") },
        { label: "No" },
      ],
    });
  };
  const backbuttonAction = () => {
    if (activeStep === 0) {
      confirmAction();
      return;
    }
    handleBack();
  };

  return (
    <>
      <Header />
      <main>
        <section className="therapies">
          <div className="container">
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === 2 ? (
                <>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </>
              ) : (
                <>
                  <br />

                  <div className="container">
                    {activeStep === 0 && <TherapyConfirmation />}
                    {activeStep === 1 && selectedScheduleSlot && <Checkout />}
                    {/* {activeStep === 2 && paymentStatus === true ? (
                      <ErrorPage />
                    ) : null} */}
                  </div>
                </>
              )}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  onClick={backbuttonAction}
                  sx={{ mr: 1 }}
                  className="btn_status"
                >
                  <i>
                    <Gr.GrLinkPrevious />
                  </i>
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  onClick={handleNext}
                  className="btn_status"
                  disabled={selectedScheduleSlot !== null ? false : true}
                >
                  {activeStep === steps.length - 1 ? (
                    <i>
                      <Gr.GrStatusGood />
                    </i>
                  ) : (
                    <i>
                      <Gr.GrLinkNext />
                    </i>
                  )}
                </Button>
              </Box>
            </Box>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default TherapyStepper;
