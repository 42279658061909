import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Utils from "../../utils/utils";
import { useNavigate } from "react-router";
import toast, { Toaster } from "react-hot-toast";
function ConfirmOtp() {
  const [otp, setOtp] = useState("");
  const { otpVerification, isLoading } = useContext(AppContext);
  const navigate = useNavigate();
  function handleOTPChange(event) {
    let newValue = event.replace(/\D/g, "");
    newValue = newValue.slice(0, 6);
    setOtp(newValue);
  }
  const verificationOtp = async (e) => {
    e.preventDefault();
    const userId = sessionStorage.getItem("userId");
    const payload = {
      userId: userId,
      otp: otp,
    };
    await otpVerification(payload, {
      onSuccess: (res) => {
        console.log(res.message, "response");
        toast.success(res.message);
        navigate("/confirm-password");
      },
      onFailed: (err) => {
        console.log(err.message);
        toast.error(err.message);
      },
    });
  };

  return (
    <div>
      <>
        <Toaster position="top-center" />
        <div class="log_logo ali-logo">
          <img src={require("../../assets/Eastman-logo1.png")} alt="" />
        </div>
        <form
          class="otp-form mt-3 mb-3"
          name="otp-form"
          onSubmit={verificationOtp}
        >
          <div class="title">
            <h3>OTP VERIFICATION</h3>
            <p class="info">
              An otp has been sent to <b>{sessionStorage.email}</b>
            </p>
            <p class="msg">Please enter OTP to verify</p>
          </div>
          <div class="otp-input-fields">
            <input
              className="otp-filed"
              maxLength={6}
              onChange={(e) => handleOTPChange(e.target.value)}
              value={otp}
              required
            />
          </div>
          <div className="sub-field">
            <button type="submit" className="field">
              Submit
            </button>
          </div>
        </form>
      </>
    </div>
  );
}

export default ConfirmOtp;
