import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RingLoader } from "react-spinners";
import "./App.css";
import ScrollToTop from "./ScrollToTop";
import BackToTop from "./components/BackToTop/BackToTop";
import AppRoutes from "./routes/routes";
import { ProtectedRoute, ProtectedAuthRoute } from "./routes/protector";
// import "react-loader-spinner/dist/loader/Circles";
import Transitions from "./Transition";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <div className="App">
        {isLoading ? (
          <div className="loader">
            <RingLoader
              height="350"
              width="350"
              color="#047484"
              // ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Transitions>
            <Router>
              <ScrollToTop />
              <Routes>
                {AppRoutes.map((route, index) => {
                  return !route.isProtected && !route.isProtectedAuth ? (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                      exact="true"
                    />
                  ) : !route.isProtected && route.isProtectedAuth ? (
                    <Route
                      key={index}
                      path={route.path}
                      element={
                        <ProtectedAuthRoute>{route.element}</ProtectedAuthRoute>
                      }
                      exact="true"
                    />
                  ) : (
                    <Route
                      key={index}
                      path={route.path}
                      element={<ProtectedRoute>{route.element}</ProtectedRoute>}
                      exact="true"
                    />
                  );
                })}
              </Routes>
              <BackToTop />
            </Router>
          </Transitions>
        )}
      </div>
    </>
  );
}

export default App;
