import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import TopBar from "../TopBar/TopBar";
import { AiFillCloseCircle, AiOutlineDown } from "react-icons/ai";
import { AppContext } from "../../context/AppContext";
import useAuth from "../../hooks/useAuth";
import Hamburger from "hamburger-react";
import * as Bs from "react-icons/bs";
import * as Hi from "react-icons/hi";
import { useId } from "react";
// css
import "./Header.css";
import { display } from "@mui/system";

function Header() {
  const { setIsSignUp, userDetails, isAuthenticated } = useContext(AppContext);
  const location = useLocation();
  const profilemenu = useLocation();
  const navigate = useNavigate();
  const [isUrl, setIsUrl] = useState(null);
  const [click, setClick] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [cartClick, setCartClick] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [addressData, setAddressData] = useState(false);
  const [showNotification, setShowNotofication] = useState(false);
  const [profileMenuBar, setProfileMenuBar] = useState();
  console.log("authenticated",isAuthenticated);
  const profilediv = useId();

  const { logoutUser } = useAuth();
  const changeNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50 ? setNavbar(true) : setNavbar(false);
    }
  };
  const profileClick = () => {
    setIsProfile(!isProfile);
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);
    
    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  }, []);
  const handleCart = () => {
    setCartClick(!cartClick);
  };
  const handleClick = () => {
    setClick(!click);
    setShowDropdown(true);
  };
  useEffect(() => {
    setIsUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    document.addEventListener("mousedown", () => {
      setIsProfile(false);
    });
  }, []);

  const goToLogin = () => {
    setIsSignUp("login");
    navigate("/auth");
  };

  const goToSignUp = () => {
    setIsSignUp("signup");
    navigate("/auth");
  };
  const goToProfile = () => {
    navigate("/profile");
  };
  const goToClassroom = () => {
    navigate("/classroom");
  };
  const handleLogout = () => {
    logoutUser();
    navigate("/auth");
  };
  const toggleProgram = () => {
    setShowDropdown(!showDropdown);
  };
  console.log("user",userDetails);
  const getAddressDetails = () => {
    if (userDetails == null) return;
    if (
      (userDetails.addressline1 != null &&
        userDetails.addressline1.length === 0) ||
      (userDetails.county != null && userDetails.county.length === 0) ||
      (userDetails.postalcode != null && userDetails.postalcode.length === 0)
    ) {
      setAddressData(true);
      setShowNotofication(true);
    }
  };

  useEffect(() => {
    getAddressDetails();
  }, [userDetails]);
  const popUp = () => {
    setShowNotofication(!showNotification);
  };
  useEffect(() => {
    let timeoutId;

    if (showNotification) {
      timeoutId = setTimeout(() => {
        closePop();
      }, 1 * 30 * 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showNotification]);

  const closePop = () => {
    setShowNotofication(false);
  };

  return (
    <>
      <TopBar />
      <div className={navbar ? "head-main scrolled" : "head-main"}>
        <div class="container d-flex position-relative">
          <div class="logo_area">
            <a href="/">
              <img src={require("../../assets/Eastman-logo1.png")} alt="" />
            </a>
          </div>
          {click ? (
            <>
              <nav>
                <ul class={click ? "nav_items active" : "nav_items"}>
                  <li>
                    <Link
                      exact={true}
                      to="/"
                      className={
                        "nav_links" + (isUrl === "/" ? " selected" : "")
                      }
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact={true}
                      to="/therapies"
                      className={
                        "nav_links" +
                        (isUrl === "/therapies" ? " selected" : "")
                      }
                    >
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact={true}
                      to="/about"
                      className={
                        "nav_links" + (isUrl === "/about" ? " selected" : "")
                      }
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    {/* <li>
                    <Link
                      exact={true}
                      to="/instructor"
                      className={
                        "nav_links" +
                        (isUrl === "/instructor" ? " selected" : "")
                      }
                    >
                      Instructor
                    </Link>
                  </li>
                    <Link
                      exact={true}
                      to="/events"
                      className={
                        "nav_links" + (isUrl === "/events" ? " selected" : "")
                      }
                    >
                      Events
                    </Link>
                  </li>

                  <li>
                    <Link exact={true} to="/blog" className="nav_links">
                      Blog
                    </Link> */}
                  </li>
                </ul>
              </nav>
            </>
          ) : (
            <>
              <nav>
                <ul className={click ? "nav_items2 active" : "nav_items2"}>
                  <li>
                    <Link
                      exact={true}
                      to="/"
                      className={
                        "nav_links2" + (isUrl === "/" ? " selected" : "")
                      }
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      exact={true}
                      to="/therapies"
                      className={
                        "nav_links2" +
                        (isUrl === "/therapies" ? " selected" : "")
                      }
                    >
                      Services
                    </Link>
                  </li>

                  <li>
                    <Link
                      exact={true}
                      to="/about"
                      className={
                        "nav_links2" + (isUrl === "/about" ? " selected" : "")
                      }
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://eastmansolicitors.com/"
                      className="nav_links2"
                      id="redirect_btn"
                      target="_blank"
                    >
                      Go to Website
                    </a>
                  </li>
                </ul>
              </nav>
            </>
          )}
          {/* <div class="search-box">
            <input type="search" name="serach_box" id="" />
            <i>
              <Bs.BsSearch />
            </i>
          </div> */}

          <div class="profile">
            {!isAuthenticated ? (
              <div class="auth-links">
                <a
                  onClick={() => goToLogin()}
                  style={{ cursor: "pointer", userSelect: "none" }}
                >
                  Login
                </a>

                <a
                  onClick={() => goToSignUp()}
                  style={{ cursor: "pointer", marginLeft: "20px" }}
                >
                  Signup
                </a>
              </div>
            ) : (
              <>
                <div
                  class={isAuthenticated ? "avatar" : "avatar placeholder"}
                  onClick={profileClick}
                >
                  {userDetails.photo != null && (
                    <img
                      src={
                        process.env.REACT_APP_AWS_IMAGE_URL + userDetails.photo
                      }
                    />
                  )}
                </div>
                <div
                  class={
                    isProfile ? "profile_details show" : "profile_details "
                  }
                >
                  <a onClick={() => goToProfile()}>Profile</a>
                  {/* <hr />
                  <a onClick={() => goToClassroom()}>Sessions</a> */}
                  <hr />
                  <a onClick={() => handleLogout()}>Logout</a>
                </div>
              </>
            )}
          </div>
          <div class="nav-icon" onClick={handleClick}>
            <Hamburger />
          </div>
          <div class="cart">
            {/* <button class="cart_btn" onClick={handleCart}>
              <Hi.HiShoppingBag />
            </button> */}
            {/* <div class={cartClick ? "cart_menu show" : "cart_menu "}>
              <p>No items in the cart</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
