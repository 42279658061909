// Packages
import React, { useState, useEffect } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Container, Card, Button, Row } from "react-bootstrap";
import Slider from "react-slick";
import Lottie from "lottie-react";
import Banner from "../../assets/judge_online_set4.jpg";
import Aos from "aos";
import { useNavigate } from "react-router";
import ReactReadMoreReadLess from "react-read-more-read-less";

// Components
import HealthAnomation from "../../assets/lottie/law.json";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Header2 from "../../components/Header2/Header2";
import BottomBar from "../../components/BottomBar/BottomBar";
import Transitions from "../../Transition";
import Popup from "../../components/Popup/Popup";
import ProgramSlider from "../../components/ProgramSlider/ProgramSlider";
import placeholder from "../../assets/avatar2.png";

// icons
import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import * as Fa from "react-icons/fa";
import * as Bi from "react-icons/bi";
import * as Md from "react-icons/md";
import * as Im from "react-icons/im";

// css
import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";

function Home() {
  Aos.init();

  const navigate = useNavigate();
  const [panel, setPanel] = useState("1");
  const [videoUp, setVideoUp] = useState(false);
  const [list, setList] = useState([]);

  const panelChange = (event, newPanel) => {
    setPanel(newPanel);
  };
  var sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Transitions>
        {/* header Starts */}
        <header>
          <Header />
          <section className="banner">
            <Container>
              <div className="banner-sec">
                <div className="lottie_bg">
                  <img src={Banner} className="banner-image" />
                </div>
                <div className="banner-cont">
                  <h1 data-aos="fade-up" data-aos-duration="2000">
                    Solicitors in East Ham
                  </h1>
                  <p data-aos="fade-up" data-aos-duration="1500">
                    <span>
                      Welcome Eastman Solicitors & Notary Online Services:{" "}
                    </span>{" "}
                    <br />
                    At Eastman Solicitors & Notary, we take pride in solving our
                    client’s personal and business legal issues. With over 20
                    years of combined legal experience, Eastman Solicitors are
                    dedicated to providing our clients with informed and
                    effective legal advise. Our services are tailored to meet
                    the legal challenges that you and your family may be facing.
                    As a rule of thumb, we treat all our clients with respect,
                    dignity, and ensure proper confidentiality of their
                    information.
                  </p>
                </div>
                <div class="" data-aos="fade-up" data-aos-duration="2000">
                  <button
                    className="course_btn"
                    onClick={() => navigate("/therapies")}
                  >
                    Start a Service <Ai.AiOutlineRight />
                  </button>
                </div>
              </div>
            </Container>
          </section>
        </header>
        {/* Header End */}
        {/* Main Content Start */}
        <main>
          
          <section className="programmes">
            <Container>
              <h1 className="program_title">
                Here's what you can expect from <br /> our Service
              </h1>
              <p className="program_sub">
                Eastman Solicitors & Notary was started as a sole practitioner
                providing legal services to the East London area community.
              </p>

              <div className="container">
                <div className="row mt-3">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div class="card pro_card1 mt-3">
                      <div className="card-img-top">
                        <img
                          src={require("../../assets/Eastman/young-happy-couple-insurance-agent-talking-while-analyzing-paperwork-during-consultations.jpg")}
                        />
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline-bottom"></span>
                        </div>
                      </div>
                      <div class="card-body proCard">
                        <h5 class="card-title">Wills & Probate</h5>
                        <ul>
                          <li>Wills</li>
                          <li>Probates</li>
                          <li>
                            Enduring Power of Attorneys Court of Protection
                            Matters
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div class="card pro_card1 mt-3">
                      <div className="card-img-top">
                        <img
                          src={require("../../assets/Eastman/close-up-hands-holding-paper-family.jpg")}
                        />
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline-bottom"></span>
                        </div>
                      </div>
                      <div class="card-body proCard">
                        <h5 class="card-title">Family Law</h5>
                        <ul>
                          <li>Divorce</li>
                          <li>Child Contact/Child Custody</li>
                          <li>
                            Financial Arrangement We undertake Legal Aid Cases
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div class="card pro_card1 mt-3">
                      <div className="card-img-top">
                        <img
                          src={require("../../assets/Eastman/medium-shot-smiley-woman-traveling.jpg")}
                        />
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline-bottom"></span>
                        </div>
                      </div>
                      <div class="card-body proCard">
                        <h5 class="card-title">Conveyancing</h5>
                        <ul>
                          <li>Sale & Purchase of Houses and Flats</li>
                          <li>Sale & Purchase of Leasehold Business</li>
                          <li>Law Society’s LEXCEL accredited</li>
                          <li>Conveyancing Quality Scheme (CQS) Accredited</li>
                          <li>On all Major Lending Panels</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div class="card pro_card1 mt-3">
                      <div className="card-img-top">
                        <img
                          src={require("../../assets/Eastman/immigration.jpg")}
                        />
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline-bottom"></span>
                        </div>
                      </div>
                      <div class="card-body proCard">
                        <h5 class="card-title">Immigration</h5>
                        <ul>
                          <li>Immigration Appeals</li>
                          <li>Tier 2 & 4 Visas</li>
                          <li>Entry Clearance Applications</li>
                          <li>Applications</li>
                          <li>Visit Visas</li>
                          <li>Asylum</li>
                          <li>Spouse Visas</li>
                          <li>Elderly Dependent Visas</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section>

          <BottomBar />
        </main>
        {/* main content End */}
        {/* Footer starts */}
        <footer>
          <Footer />
        </footer>
      </Transitions>
    </>
  );
}

export default Home;
