import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../context/SocketContext";
import { v4 as uuidv4 } from "uuid";

export default function useChat(props) {
  const { socket } = useContext(SocketContext);
  const [chatRoomId, setChatRoomId] = useState(null);
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);

  useEffect(() => {
    joinChatRoom();
    whenJoinRoom();
    whenChatCleared();
  }, []);

  useEffect(() => {
    if (chatRoomId) {
      listenRoomMessages();
    }
  }, [chatRoomId]);

  const joinChatRoom = () => {
    socket.emit("join-session-chat", props.chatRoomId);
  };

  const whenChatCleared = () => {
    socket.on("chat-cleared", (msg) => {
      // console.log("chat-cleared", msg);
      setChatMessages([]);
    });
  };

  const whenJoinRoom = () => {
    socket.on("joinedInRoom", (data) => {
      const { message, id } = data;
      // console.log("joined-room-message", message);
      setChatRoomId(id);
    });
  };

  const listenRoomMessages = () => {
    socket.on("session-message", (message) => {
      if (message != null && message != undefined) {
        setChatMessages((prev) => [...prev, message]);
      }
    });

    socket.on("session-messages", (messages) => {
      if (messages == null || messages == undefined) return;
      if (Array.isArray(messages) && message.length == 0) return;
      const chatMessages = JSON.parse(messages);
      if (Array.isArray(chatMessages) && chatMessages.length != 0) {
        const parsedMessages = chatMessages.map((message) =>
          JSON.parse(message)
        );
        setChatMessages(parsedMessages);
      }
    });
  };

  const clearChat = () => {
    if (chatRoomId == null || chatRoomId == undefined) return;
    socket.emit("clear-chat", `session-${chatRoomId}`);
  };

  const sendMessageToRoom = () => {
    // debugger;
    if (props.userDetails == null || props.userDetails == undefined) return;
    if (chatRoomId == null || chatRoomId == undefined) return;
    if (!message || message.length == 0) return;

    // debugger;

    const { _id, name, profile } = props.userDetails;

    const roomMessage = {
      messageId: uuidv4(),
      userId: _id,
      name: name,
      message: message,
      room: `session-${chatRoomId}`,
      image: profile,
      userType: props.userType,
      isEmoji: false,
    };

    socket.emit("send-session-message", roomMessage);
    setMessage("");
  };

  return {
    chatRoomId,
    message,
    setMessage,
    chatMessages,
    clearChat,
    sendMessageToRoom,
  };
}
