import React from "react";
import { Container } from "react-bootstrap";
import "./TopBar.css"

import * as Fa from "react-icons/fa";
import * as Ai from "react-icons/ai"

function TopBar() {
  return (
    <>
      <div className="top-bar">
        <Container>
          <div className="top-cont">
            <p>
              <i>
                <Fa.FaPhoneSquareAlt />
              </i>{" "}
              0208 912 2280
            </p>
            <p>
              <i>
                <Ai.AiFillMail />
              </i>{" "}
              info@eastmansolicitors.com
            </p>
          </div>
        </Container>
      </div>
      <div class="header2">
        <Container></Container>
      </div>
    </>
  );
}

export default TopBar;
