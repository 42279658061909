import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router";
import "./Login.css";
import Login from "../../components/Login/Login";
import Signup from "../../components/Signup/Signup";
import { AppContext } from "../../context/AppContext";
import ConfirmPassword from "../ForgotPassword/ConfirmPassword";
import OTPVerification from "../../components/Signup/OTPVerification";
import PageNotFound from "../Errors/PageNotFound";
import { Navigate } from "react-router";
import { Modal } from "antd";

function Auth() {

  const { isSignup, setIsSignUp } = useContext(AppContext);

  useEffect(() => {
    checkRegisteredDetails();
  }, []);



  const checkRegisteredDetails = () => {
    const registeredDetails =
      window.sessionStorage.getItem("RegisteredDetails");
    if (!registeredDetails) return;
    setIsSignUp("otp");
  };

  const goToLogin = () => setIsSignUp("login");
  const goToSignUp = () => setIsSignUp("signup");

 

  return (
    <>
      {/* <Modal
        title="Session Expired"
        open={expiredModal}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { backgroundColor: "#7f8c78" } }}
        centered
      >
        <h5>
          Your session has expired. Please login to pick up where you left off.
        </h5>
      </Modal> */}
      <section class="login_page">
        <div class="log_area">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="log_cont">
                <div class="log_logo">
                  <img src={require("../../assets/Eastman-logo1.png")} alt="" />
                </div>
                {isSignup == "signup" && (
                  <>
                    <Signup />
                    <p className="signup_link">
                      You already have an Account?{" "}
                      <a onClick={() => goToLogin()}>Login</a>
                    </p>
                  </>
                )}
                {isSignup == "login" && (
                  <>
                    <Login />
                    <p className="signup_link">
                      Don't have an account yet?{" "}
                      <a onClick={() => goToSignUp()}>Signup</a>
                    </p>
                    {/* <p className="gotohome">
                      Go to <a href="/">Home</a>
                    </p> */}
                  </>
                )}{" "}
                {isSignup == "otp" && <OTPVerification />}
                {!isSignup && <Navigate to="/pagenotfound" />}
                <br/>
                <div className="or">
                  <span>OR</span>
                </div>
                <p id="home">
                  Go to <a href="/">Home</a>
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 log_img_main">
              <div class="log_img">
                <img
                  src={require("../../assets/Eastman/judge_online_set4.jpg")}
                  alt=""
                  className="law-pro"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Auth;
