import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import "./Checkout.css";
import * as Fi from "react-icons/fi";
import * as Ai from "react-icons/ai";
import { AppContext } from "../../context/AppContext";
import Signup from "../../components/Signup/Signup";
import Login from "../../components/Login/Login";
import StripePaymentElement from "./StripePaymentElement";
import { Elements } from "@stripe/react-stripe-js";
import toast, { Toaster } from "react-hot-toast";
import Formatter from "../../helpers/Formatter";
import Validator from "../../utils/validator";

function Checkout() {
  const navigate = useNavigate();
  const params = useParams();

  const [isAuthChange, setIsAuthChange] = useState(false);
  const [therapistName, setTherapistName] = useState(null);
  const [error, setError] = useState(false);
  const [selectedTherapy, setSelectedTherapy] = useState(null);
  const { activeStep, setActiveStep } = useContext(AppContext);
  const [scheduleData, setScheduleData] = useState(null);

  const {
    isAuthenticated,
    setIsSignUp,
    isSignup,
    therapies,
    createPaymentIntent,
    stripePaymentClientSecret,
    setStripeClientSecret,
    stripePromise,
    options,
    setSelectedScheduleSlot,
    selectedScheduleSlot,
    billingAddress,
    setBillingAddress,
    selectedBookingDate,
  } = useContext(AppContext);

  useEffect(() => {
    initFunction();
    const data = JSON.parse(sessionStorage.getItem("selectedTherapy"));
    console.log("data for schedule", data);
    const localData = JSON.parse(sessionStorage.getItem("selectedTherapy"));
    if (localData != null) {
      setSelectedScheduleSlot(localData);
      return;
    }
  }, []);

  useEffect(() => {
    console.log("stripePaymentClientSecret", stripePaymentClientSecret);
  }, [stripePaymentClientSecret]);

  const initFunction = () => {
    if (!params.id) return;
    const therapy = therapies.find((therapy) => therapy._id == params.id);
    if (!therapy) return;
    setSelectedTherapy(therapy);
  };

  const navigateToSuccessPage = () => {
    navigate("/success");
  };
  // const goToLogin = () => {
  //   navigate("/auth");
  // };

  const createPaymentIntentRequest = async () => {
    if (
      billingAddress.fullName.length === 0 ||
      billingAddress.email.length === 0 ||
      billingAddress.postcode.length === 0 ||
      billingAddress.state.length === 0
    ) {
      toast.error("Please fill all the requires forms!");
      return;
    }
    if (Validator.validateEmail(billingAddress.email) === false) {
      toast.error("Email address is invalid");
      return;
    }
    if (
      !params.id ||
      stripePaymentClientSecret ||
      !selectedBookingDate ||
      Object.keys(billingAddress).length == 0
    ) {
      return;
    }

    await createPaymentIntent(
      {
        amount: selectedScheduleSlot.schedule.therapistDetails.price,
        scheduleDetails: {
          therapyDetails:
            selectedScheduleSlot.schedule.theraphyDetails.therapyDetails,
          therapistDetails: selectedScheduleSlot.schedule.therapistDetails,
          slotDetails: {
            ...selectedScheduleSlot.slot,
            date: selectedScheduleSlot.slot.date,
          },
        },
        billingAddress,
      },

      {
        onSuccess: (clientSecret) => {
          setStripeClientSecret(clientSecret);
        },
        onFailed: (error) => {
          console.error("Error", error);
        },
      }
    );
  };

  const ChangeAuth = () => {
    setIsAuthChange(!isAuthChange);
    setIsSignUp("signup");
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setBillingAddress({ ...billingAddress, [name]: value });
  };

  useEffect(() => {
    if (selectedScheduleSlot != null && selectedScheduleSlot.schedule != null) {
      console.log(selectedScheduleSlot.schedule);
      setTherapistName(
        selectedScheduleSlot.schedule.therapistDetails?.firstName +
          " " +
          selectedScheduleSlot.schedule.therapistDetails?.lastName
      );
    }
  }, [selectedScheduleSlot]);
  console.log(selectedScheduleSlot, "selectedScheduleSlot");
  return (
    <>
      <Toaster position="top-center" />
      {/* <header>
        <div class="check_head">
          <div class="container">
            <div class="row">
              <div class="col-6 logos">
                <div class="logo_area">
                  <a href="/">
                    <img src={require("../../assets/logo-web.png")} alt="" />
                  </a>
                </div>
              </div>
              <div class="col-6"></div>
            </div>
          </div>
        </div>
      </header> */}
      <main>
        <section class="checkout_main">
          <div class="container">
            {/* <button className="back_btn" onClick={() => navigate("/therapies")}>
              <Ai.AiOutlineLeft />
            </button> */}
            <div class="billing">
              <h1>Checkout</h1>
              <div class="row flex-column-reverse flex-lg-row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  {isAuthenticated ? (
                    <form onSubmit={() => {}}>
                      <div class="form-group checkout_form">
                        <label for="">First & Last name</label>
                        <input
                          type="text"
                          name="fullName"
                          class="form-control checkout_name"
                          placeholder="Your full name"
                          onChange={onChange}
                          value={billingAddress.fullName}
                          required
                        />
                      </div>
                      <div class="form-group checkout_form mt-2">
                        <label for="">Email address</label>
                        <input
                          name="email"
                          type="email"
                          class="form-control checkout_mail"
                          placeholder="Your Email addresss"
                          onChange={onChange}
                          value={billingAddress.email}
                          required
                        />
                      </div>
                      <div class="form-group checkout_form mt-2">
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <label for="">State/County</label>
                              <input
                                name="state"
                                type="text"
                                placeholder="Enter state or county"
                                class="form-control checkout_state"
                                onChange={onChange}
                                value={billingAddress.state}
                                required
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label for="">Zip/Postcode </label>
                              <input
                                name="postcode"
                                type="text"
                                placeholder="Enter Postcode"
                                class="form-control checkout_po"
                                onChange={onChange}
                                value={billingAddress.postcode}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="payement_method checkout_form">
                        <h5>Payment Method</h5>
                        <div class="credit_form"></div>
                        <div
                          class="payment stripe"
                          onClick={() => createPaymentIntentRequest()}
                        >
                          <div class="payment_ico1"></div>
                          <i>
                            <Fi.FiChevronDown />
                          </i>
                        </div>
                        <div
                          class={
                            stripePaymentClientSecret
                              ? "credit_card"
                              : "credit_card hide"
                          }
                        >
                          {stripePaymentClientSecret && (
                            <Elements stripe={stripePromise} options={options}>
                              <StripePaymentElement
                                paymentSuccess={() => {
                                  navigateToSuccessPage();
                                  // setActiveStep(2)
                                }}
                                paymentFailure={() => {}}
                              />
                            </Elements>
                          )}
                        </div>
                      </div>
                    </form>
                  ) : (
                    <>
                      <div class="container">
                        <div class="no_Auth">
                          <h2>Oops! You haven't logged in! Please </h2>
                          <button className="login_" onClick={ChangeAuth}>
                            {!isAuthChange ? "SignUp" : "login"}
                          </button>
                          <h5>(OR)</h5>
                        </div>
                        {!isSignup ? (
                          <>
                            <Signup />
                          </>
                        ) : (
                          <>
                            <Login checkoutPage={true} />
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  {selectedScheduleSlot.slot && (
                    <div class="card billing_card">
                      <h5>Billing Summary</h5>
                      <table>
                        {selectedScheduleSlot.schedule.theraphyDetails && (
                          <tr>
                            <td id="bill_title">Service :</td>
                            <td className="bill_data">
                              {
                                selectedScheduleSlot.schedule.theraphyDetails
                                  .therapyDetails.therapyName
                              }
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td id="bill_title">Date & Time :</td>
                          <td className="bill_data">
                            {Formatter.formatDate(
                              selectedScheduleSlot.slot.date
                            )}{" "}
                            |{" "}
                            {Formatter.formatTime(
                              selectedScheduleSlot.slot.startTime
                            )}
                            -
                            {Formatter.formatTime(
                              selectedScheduleSlot.slot.endTime
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td id="bill_title">Lawyer name:</td>
                          <td className="bill_data">{therapistName}</td>
                        </tr>
                        <tr>
                          <td class="amount">Amount</td>
                          <td class="amount">
                            {selectedScheduleSlot.slot != null
                              ? `£${selectedScheduleSlot.schedule.therapistDetails.price}`
                              : ""}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer></footer>
    </>
  );
}

export default Checkout;
