import React, { useState } from "react";
import BaseClient from "../helpers/base_client";
import { APIEndpoints } from "../constants/ApiEndPoints";

export default function useTherapists() {
  const [therapists, setTherapists] = useState(null);
  const [therapistLoading, setTherapistLoading] = useState(false);

  const getAllTherapists = async () => {
    try {
      setTherapistLoading(true);

      await BaseClient.get(APIEndpoints.getAllInstructor, {
        onSuccess: (res) => {
          if (res.Instructors != null && Array.isArray(res.Instructors)) {
            setTherapists(res.Instructors);
          }
        },
        onFailed: (error) => {
          console.error("Error", error);
        },
      });
    } finally {
      setTherapistLoading(false);
    }
  };

  return { getAllTherapists, therapistLoading, therapists };
}
