import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProfileHeader from "../../components/ProfileHeader/ProfileHeader";
import "./Profile.css";
import * as Bs from "react-icons/bs";
import * as Bi from "react-icons/bi";
import { Row, Col, Container, Card } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { AppContext } from "../../context/AppContext";
import SessionTile from "./SessionTile";
import { SocketContext } from "../../context/SocketContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import jwt_decode from "jwt-decode";
import Formatter from "../../helpers/Formatter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import * as Md from "react-icons/md";
import * as Ai from "react-icons/ai";
import placeholder from "../../assets/placeholder01.jpg";
import Utils from "../../utils/utils";
import toast, { Toaster } from "react-hot-toast";

function Profile() {
  const { logoutUser } = useAuth();
  const { bookedTherapies, userDetails, setBookedTherpies, loading } =
    useContext(AppContext);
  const { socket } = useContext(SocketContext);

  const navigate = useNavigate();
  const { get, check } = useLocalStorage("UserPersistent");
  const [socketEvent, setSocketEvent] = useState(null);
  const [upcomingCount, setUpcomingCount] = useState(null);

  const onClickLogout = () => {
    logoutUser();
    navigate("/auth");
  };

  // useEffect(() => {
  //   convertBookedTherapiesToSingleArray();
  // }, [bookedTherapies]);

  // const convertBookedTherapiesToSingleArray = () => {
  //   const bookedTherapisesList = [];

  //   if (
  //     !bookedTherapies ||
  //     !Array.isArray(bookedTherapies) ||
  //     bookedTherapies.length == 0
  //   ) {
  //     return;
  //   }

  //   bookedTherapies.forEach((therapy) => {
  //     const element = [...therapy.bookings, therapy.therapistDetails];
  //     bookedTherapisesList.push(element);
  //   });

  //   console.log("bookedTherapisesList", bookedTherapisesList);
  // };

  // useEffect(() => {
  //   if (socket != null) {
  //     listenForStreamEvents();
  //   }
  // }, [socket]);

  // useEffect(() => {
  //   handleStreamEvents();
  // }, [socketEvent]);

  const listenForStreamEvents = () => {
    if (!socket) return;
    socket.on("recieve-message", (message) => {});
    socket.on("streamStarted", (data) => {
      setSocketEvent({ event: "streamStarted", data });
    });
    socket.on("streamStopped", (data) => {
      setSocketEvent({ event: "streamStopped", data });
    });
  };

  const handleStreamEvents = () => {
    if (socketEvent == null || setSocketEvent == undefined) return;

    const { data, id } = socketEvent.data;

    //?? HANDLE SOCKET EVENT
    switch (socketEvent.event) {
      case "streamStarted":
        pushNewTherapyToBookedTherapies(data);
        break;
      case "streamStopped":
        removeTherpyFromBookedTherapies(id);
        break;
      default:
        console.log("Invalid Socket Event !!");
        break;
    }
  };

  const pushNewTherapyToBookedTherapies = (data) => {
    if (!data) return;

    const userId = getUserId();

    if (!userId || !data.bookedUser) return;

    if (data.bookedUser != userId) return;

    const currentBookedTherapies = bookedTherapies;

    if (!Array.isArray(currentBookedTherapies)) return;

    let removeIndex = -1;
    if (currentBookedTherapies.length !== 0) {
      for (let index = 0; index < currentBookedTherapies.length; index++) {
        if (currentBookedTherapies[index]._id === data._id) {
          removeIndex = index;
          break;
        }
      }

      if (removeIndex != -1) {
        currentBookedTherapies.splice(removeIndex, 1);
      }

      currentBookedTherapies.push(data);
      setBookedTherpies((prev) => [...prev, currentBookedTherapies]);

      return;
    }

    currentBookedTherapies.push(data);
    setBookedTherpies((prev) => [...prev, currentBookedTherapies]);
  };

  const removeTherpyFromBookedTherapies = (id) => {
    if (!id) return;

    const currentBookedTherapies = bookedTherapies;
    let removeIndex = -1;

    if (currentBookedTherapies.length !== 0) {
      for (let index = 0; index < currentBookedTherapies.length; index++) {
        if (currentBookedTherapies[index]._id === id) {
          removeIndex = index;
          break;
        }
      }

      if (removeIndex != -1) {
        currentBookedTherapies.splice(removeIndex, 1);
        setBookedTherpies((prev) => [...prev, currentBookedTherapies]);
      }
    }
  };
  const getUserId = () => {
    if (!check()) return;
    const localStorageValue = JSON.parse(get());
    const { accessToken } = localStorageValue;
    if (!accessToken) return;
    const { Id } = jwt_decode(accessToken);
    return Id;
  };
  console.log(bookedTherapies, "listofbooked");

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <header>
        <ProfileHeader />
      </header>
      <Toaster position="top-center" />
      <main>
        <div className="main">
          <div className="profile_div">
            <div className="pro_div">
              <Container>
                <Row>
                  <Col className="first_Card">
                    <h5 className="ms-2 pt-4">Profile</h5>
                    <Card className="pro_cards profile__">
                      <Card.Img
                        variant="top"
                        src={
                          userDetails.photo != null
                            ? process.env.REACT_APP_AWS_IMAGE_URL +
                              userDetails.photo
                            : require("../../assets/Eastman/Lawyers/place.jpg")
                        }
                        alt=""
                        className="profileImg"
                      />
                      <Card.Title className="text-center">
                        {userDetails.name != null
                          ? userDetails.name
                          : "Unknown"}
                      </Card.Title>
                      <div className="text-center">
                        <p style={{ marginBottom: "0px" }}>
                          {userDetails.email != null
                            ? userDetails.email
                            : "Unknown"}
                        </p>
                      </div>
                      {/* <div class="d-flex justify-content-center mb-3">
                        <button
                          className="edit-pro pro-btn hegh"
                          onClick={() => navigate("/edit-profile")}
                        >
                          <i className="con">
                            <Ai.AiOutlineEdit />
                          </i>
                          Edit Profile
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-dark"
                          onClick={() => onClickLogout()}
                        >
                          <i className="loh">
                            <Md.MdLogout />
                          </i>
                        </button>
                      </div> */}
                      <div class="d-flex justify-content-center mb-3">
                        <button
                          className="edit-pro pro-btn hegh"
                          onClick={() => navigate("/edit-profile")}
                        >
                          <i className="con">
                            <Ai.AiOutlineEdit />
                          </i>
                          Edit Profile
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-dark"
                          onClick={() => onClickLogout()}
                        >
                          <i className="loh">
                            <Md.MdLogout />
                          </i>
                        </button>
                      </div>
                    </Card>
                  </Col>

                  <Col xl={8}>
                    <div class="container mt-3">
                      <h5 className="pt-4 ml-1"> Present/Upcoming Sessions</h5>
                      <Slider {...slickSettings}>
                        {!loading &&
                          bookedTherapies.length != null &&
                          bookedTherapies
                            .filter((therapy, index, self) => {
                              return (
                                index ===
                                self.findIndex((t) => t._id === therapy._id)
                              );
                            })
                            .map((therapy) => {
                              console.log("therapy numbers", therapy);
                              if (
                                therapy.streamStatus != "STOPPED" &&
                                therapy.bookingStatus != "CANCELED" &&
                                therapy.bookingStatus != "Rescheduled" &&
                                therapy.therapistDetails != undefined
                              ) {
                                const therapyDetails = therapy.theraphyDetails;
                                const therapistDetails =
                                  therapy.therapistDetails;
                                const schedule = therapy.scheduleDetails;
                                // console.log("therapyDetails", therapyDetails);
                                const rescheduleData = therapy.requestDetails;
                                console.log("rescheduleData", rescheduleData);

                                return (
                                  <SessionTile
                                    therapyName={
                                      therapyDetails != null
                                        ? therapyDetails.therapyName
                                        : "N/A"
                                    }
                                    therapy={therapy != null && therapy}
                                    therapistName={
                                      therapistDetails != null
                                        ? therapistDetails.name
                                        : "N/A"
                                    }
                                    date={
                                      therapy != null
                                        ? Formatter.formatDate(therapy.date)
                                        : "N/A"
                                    }
                                    rescheduleData={rescheduleData}
                                    startTime={therapy.startTime}
                                    endTime={therapy.endTime}
                                    image={
                                      therapyDetails != null
                                        ? process.env.REACT_APP_AWS_IMAGE_URL +
                                          therapyDetails.photo
                                        : placeholder
                                    }
                                    desigination={
                                      therapistDetails != null &&
                                      therapistDetails.desigination
                                    }
                                    showView={therapy.streamStatus == "STARTED"}
                                    onClickView={function () {
                                      return navigate(
                                        `/classroom/${therapy._id}`
                                      );
                                    }}
                                    scheduleStatus={
                                      therapy.scheduleStatus != null
                                        ? therapy.scheduleStatus
                                        : "None"
                                    }
                                  />
                                );
                              }
                            })}
                        {(!bookedTherapies ||
                          (bookedTherapies.length == 0 &&
                            bookedTherapies == null &&
                            bookedTherapies == "undefined")) && (
                          <div className="card slider mx-auto text-center">
                            <p className="paraph">No Upcoming Sessions</p>
                          </div>
                        )}
                      </Slider>
                    </div>

                    <Col>
                      <div class="container">
                        {bookedTherapies != null &&
                          bookedTherapies.length != 0 && (
                            <h6 className="">Finished Sessions</h6>
                          )}

                        <Card className="ex_cards card-ht">
                          <div className="container">
                            <table class="table table-striped profile-table text-center">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Service</th>
                                  <th>Lawyer Name</th>
                                  <th>Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                {bookedTherapies != null &&
                                  Array.isArray(bookedTherapies) &&
                                  bookedTherapies.length != 0 &&
                                  bookedTherapies.map(
                                    (bookedTherapy, index) => {
                                      if (
                                        bookedTherapy.streamStatus ==
                                          "STOPPED" ||
                                        bookedTherapy.streamStatus == "FINISHED"
                                      ) {
                                        const schedule =
                                          bookedTherapy.scheduleDetails;
                                        const therapyDetails =
                                          bookedTherapy.theraphyDetails;
                                        const therapistDetails =
                                          bookedTherapy.therapistDetails;

                                        return (
                                          <tr key={index}>
                                            <td>
                                              {Formatter.formatDate(
                                                bookedTherapy.date
                                              )}
                                            </td>
                                            <td>
                                              {therapyDetails != null
                                                ? therapyDetails.therapyName
                                                : "N/A"}
                                            </td>
                                            <td>
                                              {therapistDetails != null
                                                ? therapistDetails.name
                                                : "N/A"}
                                            </td>

                                            <td>
                                              {schedule.startTime}-
                                              {schedule.endTime}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    }
                                  )}
                                {!bookedTherapies||(
                                  bookedTherapies.length ===0&&(
                                    <>
                                    <td colSpan="4">
                                      <p className="let">
                                        No session to show.
                                      </p>
                                    </td>
                                    </>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Card>
                      </div>
                    </Col>
                    <Col>
                      <div className="container mt-3">
                        <h6 className="">Cancelled Session Refund Status</h6>
                        {bookedTherapies != null &&
                          Array.isArray(bookedTherapies) &&
                          bookedTherapies.map((booking, index) => {
                            console.log("booking", booking);
                            const cancelled = booking.cancelInformation;
                            if (booking.bookingStatus === "CANCELED") {
                              return (
                                <Card
                                  key={index}
                                  className="card p-3 mb-3 new-card"
                                >
                                  <div className="row">
                                    <div className="col">
                                      <small className="m-3">
                                        Service :{" "}
                                        {booking.theraphyDetails.therapyName}
                                      </small>
                                      <small className="m-3">
                                        Date: {Utils.formatDate(booking.date)}
                                      </small>
                                      <small>
                                        Time:{" "}
                                        {booking.scheduleDetails.startTime} -{" "}
                                        {booking.scheduleDetails.endTime}
                                      </small>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col">
                                      <small className="m-3">
                                        Cancelled By: {cancelled.cancelledBy}
                                      </small>
                                      <small className="m-3">
                                        Cancelled Date:{" "}
                                        {Utils.formatDate(
                                          cancelled.cancelledDate
                                        )}
                                      </small>
                                      {booking.refundStatus == "None" ? (
                                        <p className="stat red">
                                          Not Initiated
                                        </p>
                                      ) : (
                                        <p className="stat ">
                                          {booking.refundStatus}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </Card>
                              );
                            }
                          })}
                      </div>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Profile;
