import React, { useContext, useEffect, useState } from "react";
import Formatter from "../../helpers/Formatter";
import * as Bs from "react-icons/bs";
import { Row, Col, Card } from "react-bootstrap";
import * as Fa from "react-icons/fa";
import { AppContext } from "../../context/AppContext";
import toast from "react-hot-toast";
import moment from "moment";
import { Space, TimePicker, Modal } from "antd";
import dayjs from "dayjs";

export default function SessionTile(props) {
  const {
    cancelSession,
    cancelLoading,
    getBookedScheduleOfUser,
    RescheduleSession,
  } = useContext(AppContext);

  console.log("status", props);
  const [reStatus, setReStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initial, setInitial] = useState({
    date: "",
    startTime: "",
    endTime: "",
    reason: "",
  });
  useEffect(() => {
    setInitial({
      date: props.date || "",
      startTime: props.startTime || "",
      endTime: props.endTime || "",
    });
    console.log("iniyt", initial);
  }, [props]);
  console.log("props", props);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  console.log(props.startTime, "EndTime");
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onDateSelect = (date, dateString) => {
    // setSelectedDate(date);
    // console.log(selectedDate)
  };

  const onDateInputChange = (value) => {
    const dateConvert = new Date(value);
    if (!initial.date) return;
    setInitial((prevValues) => ({
      ...prevValues,
      date: dateConvert,
    }));
  };
  const onChangeTimePicker = (time, timeString, type) => {
    switch (type) {
      case "startTime":
        setInitial((prev) => ({ ...prev, startTime: timeString }));
        return;
      case "endTime":
        setInitial((prev) => ({ ...prev, endTime: timeString }));
        return;
      default:
        return;
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setInitial((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const confirmAction = (onOkCallback) => {
    Modal.confirm({
      title: "Confirm Action",
      content: "Are you sure you want to Cancel this session?",
      onOk: onOkCallback,
      onCancel: () => console.log("Cancel"),
    });
  };

  const handleCancelSession = async () => {
    confirmAction(async () => {
      const payload = {
        therapistId: props.therapy.therapistId,
        bookingId: props.therapy._id,
        cancelledBy: "User",
        cancelledDate: new Date(),
      };
      await cancelSession(payload, {
        onSuccess: async (res) => {
          toast.success(res.message);
          await getBookedScheduleOfUser();
          // window.location.reload();
        },
        onFailed: (err) => {
          toast.error("Failed to Cancel Session!");
        },
      });
    });
  };

  const handleModal = () => {
    setIsModalOpen(true);
  };

  const handleReschedule = async (e) => {
    e.preventDefault();

    const payload = {
      bookingId: props.therapy._id,
      reason: initial.reason,
      startTime: initial.startTime,
      endTime: initial.endTime,
      date: initial.date,
    };

    await RescheduleSession(payload, {
      onSuccess: async (res) => {
        setIsModalOpen(false);
        toast.success(res.message);
        await getBookedScheduleOfUser();
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };
  console.log("rescheduleData", props.rescheduleData);
  useEffect(() => {
    const status = props.rescheduleData.map((data, index) => {
      const rescheduleDetails = data.responseStatus;
      if (rescheduleDetails == "Declined") {
        setReStatus(true);
      }
    });
  });
  console.log("startTime", props);
  return (
    <>
      <Modal
        title="Reschedule Session"
        visible={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <form onSubmit={(e) => handleReschedule(e)}>
          <label htmlFor="">Date</label>
          <input
            type="date"
            className="form-control"
            value={moment(initial.date, "DD-MM-YYYY").format("YYYY-MM-DD")}
            onChange={(e) => onDateInputChange(e.target.value)}
            min={moment().format("YYYY-MM-DD")}
          />
          <div className="form-group mt-3">
            <label htmlFor="">Start Time</label>
            <Space
              direction="vertical"
              style={{
                width: "100%",
                borderColor: "transparent",
              }}
            >
              <TimePicker
                use12Hours
                format="h:mm A"
                style={{
                  width: "100%",
                  borderColor: "transparent",
                  outline: "none",
                  boxShadow: "0 0 0 transparent",
                }}
                className="form-control"
                placeholder="Select Start Time"
                value={
                  dayjs(initial.startTime, "h:mm A").isValid()
                    ? dayjs(initial.startTime, "h:mm A")
                    : null
                }
                onChange={(time, timeString) =>
                  onChangeTimePicker(time, timeString, "startTime")
                }
              />
            </Space>
          </div>
          <div className="form-group mt-3">
            <label htmlFor="">End Time</label>
            <Space
              direction="vertical"
              style={{
                width: "100%",
                borderColor: "transparent",
              }}
            >
              <TimePicker
                use12Hours
                format="h:mm A"
                style={{
                  width: "100%",
                  borderColor: "transparent",
                  outline: "none",
                  boxShadow: "0 0 0 transparent",
                }}
                className="form-control"
                value={
                  dayjs(initial.endTime, "h:mm A").isValid()
                    ? dayjs(initial.endTime, "h:mm A")
                    : null
                }
                onChange={(time, timeString) =>
                  onChangeTimePicker(time, timeString, "endTime")
                }
              />
            </Space>
          </div>
          <div className="form-group mt-3">
            <label htmlFor="">Reason</label>
            <input
              className="p-3 reason-in"
              placeholder="write a Reason"
              value={initial.reason}
              onChange={(e) => handleChange(e)}
              name="reason"
            />
          </div>
          <div className="mt-3 mb-3 mx-auto text-center">
            <button type="submit" className="brk">
              Submit
            </button>
          </div>
        </form>
      </Modal>
      <Card className="pro profile_card p-4 m-2">
        {props.showView == true && (
          <div class="livenow">
            <div class="live-dot"></div>
            <p>Live</p>
          </div>
        )}
        {/* <div class="line_1"></div> */}
        <Row>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="therapy_img_">
              <img
                src={
                  props.image
                    ? props.image
                    : require("../../assets/Eastman/Group 1.png")
                }
                alt=""
              />
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 therapy_info">
            <div class="line_1"></div>
            <h5 className="text-center">{props.therapyName}</h5>
            <p class="date pt-1">
              <Bs.BsCalendarEvent /> {props.date}
            </p>
            <p class="time">
              <Bs.BsClock />
              {Formatter.formatTime(props.startTime)} -{" "}
              {Formatter.formatTime(props.endTime)}
            </p>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 therapy_status">
            <div class="line_1"></div>
            <p className="therapist_ mt-4">{props.therapistName}</p>
            <p class="desig">{props.desigination}</p>
            {props.showView == true && props.scheduleStatus != "pending" ? (
              <button className="join" onClick={props.onClickView}>
                <i className="ico">
                  <Fa.FaPlay />
                </i>
                Join Session
              </button>
            ) : (
              ""
            )}
          </div>
        </Row>

        <Row className="mt-3 mb-3">
          <div className="col-md-4 mx-auto text-center">
            {props.therapy.streamStatus != "STARTED" && (
              <button className="canceld" onClick={handleCancelSession}>
                Cancel
              </button>
            )}
          </div>
          <div className="col-md-4 mx-auto"></div>
          <div className="col-md-4 text-center">
            {" "}
            {props.therapy.scheduleStatus == "requested" &&
              props.rescheduleData.responseStatus != "Declined" && (
                <p className="resch-para">Reschedule Requested</p>
              )}
            {props.therapy.reScheduleStatus == true &&
              props.therapy.scheduleStatus != "requested" && (
                <p className="grn">Rescheduled</p>
              )}
            {reStatus == true &&
              props.therapy.scheduleStatus != "requested" &&
              props.therapy.reScheduleStatus != true && (
                <p className="red">Reschedule Request is Declined!</p>
              )}
            {props.showView != true && (
              <button className="reschedules" onClick={(e) => handleModal(e)}>
                Re-schedule
              </button>
            )}
          </div>
        </Row>
        {props.therapy.scheduleStatus == "pending" && (
          <p className="pending_ mx-auto">
            This session must be accepted by the lawyer before it can begin.
          </p>
        )}
      </Card>

      {/* card End  */}

      {/* <p className="current_session_title">
        Therapy Name :{" "}
        <span
          style={{ float: "right", marginRight: "30px" }}
          className="text-muted"
        >
          {props.therapyName}
        </span>
      </p>
      <hr />
      <p className="current_session_title">
        Therapist:{" "}
        <span
          style={{ float: "right", marginRight: "30px" }}
          className="text-muted"
        >
          {props.therapistName}
        </span>
      </p>
      <hr />
      <p className="current_session_title">
        Date:{" "}
        <span
          style={{ float: "right", marginRight: "30px" }}
          className="text-muted"
        >
          {props.date}
        </span>
      </p>
      <hr />
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-2">
          <p className="current_session_title">
            From: {Formatter.formatTime(props.startTime)} To:{" "}
            {Formatter.formatTime(props.endTime)}
          </p>
        </div>
      </div>
      <hr />
      {props.showView == true && (
        <p className="current_session_title text-center">
          <button className="view_btn" onClick={props.onClickView}>
            View
          </button>
        </p>
      )} */}
    </>
  );
}
