import React, { useContext, useEffect, useRef, useLayoutEffect } from "react";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import * as Md from "react-icons/md";
import * as Ri from "react-icons/ri";
import * as Fa from "react-icons/fa";
import * as Si from "react-icons/si";

import "./LiveSession.css";
import { Row, Col, Container } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MediaPlayer from "../../pages/StreamPage/Mediaplayer";
import useChat from "../../hooks/useChat";

function ChatSession(props) {
  const newMsg = useRef(null);
  const pinnedref = useRef(false);
  const {
    bookedTherapies,
    remoteUsers,
    userDetails,
    players,
    setPlayers,
    bookedUsersList,
    userData,
    setuserData,
  } = useContext(AppContext);

  const {
    chatRoomId,
    message,
    chatMessages,
    clearChat,
    sendMessageToRoom,
    setMessage,
  } = useChat({
    chatRoomId: props.chatRoomId,
    userDetails,
    userType: "Client",
  });
  //?? For Therapist we'e have to change the userDetails Object & userType to Therapist.

  const onChangeMessageField = (e) => {
    setMessage(e.target.value);
  };

  const onPressKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      sendMessageToRoom();
    }
  };

  useEffect(() => {
    const container = newMsg.current;
    if (!pinnedref.current) {
      container.scrollTop = container.scrollHeight;
    }
  });

  useLayoutEffect(() => {
    const container = newMsg.current;
    const pinned =
      container.scrollHeight - container.scrollTop === container.clientHeight;
    pinnedref.current = pinned;
    console.log((pinnedref.current = pinned));
  });

  // useEffect(() => {
  //   console.log("bookedUsersList", bookedUsersList);
  // }, [bookedUsersList]);

  // const getRemoteUserById = (id) => {
  //   const uid = id.substring(4);

  //   if (!bookedUsersList || bookedUsersList.length == 0) return;
  //   const user = bookedUsersList.find((user) => uid == user._id);
  //   if (!user) return;
  //   setuserData(user)
  //   return user.name;
  // };

  return (
    <>
      <Container>
        <div className="chat_main">
          <div className="chat_head">
            <div className="tab-div mx-auto">
              <Tabs defaultActiveKey="All" className="mx-auto m-3" justify>
                <Tab
                  eventKey="All"
                  title={
                    <span style={{ fontSize: "13px" }}>
                      <i style={{ fontSize: "17px", marginRight: "2px" }}>
                        <Ri.RiUserSmileFill />
                      </i>{" "}
                      Participants
                    </span>
                  }
                  id="scrollable"
                >
                  <div className="scroll-area">
                    {props.sidePlayerSrc != null &&
                      Object.keys(props.sidePlayerSrc).length != 0 && (
                        <div
                          className="card part_card mx-auto"
                          id={"side_player"}
                        >
                          <div className="user_name">
                            <span>Me</span>
                          </div>

                          <MediaPlayer
                            videoTrack={props.sidePlayerSrc.videoTrack}
                            audioTrack={props.sidePlayerSrc.audioTrack}
                            style={{ aspectRatio: "16/9" }}
                            ref={React.createRef()}
                          />
                        </div>
                      )}
                    {remoteUsers.map((user, index) => {
                      const player = players.find((p) => p.uid === user.uid);
                      if (!player) {
                        const newPlayer = {
                          uid: user.uid,
                          ref: React.createRef(),
                        };
                        setPlayers([...players, newPlayer]);
                      }
                      // const matches = props.checkMainPlayerUidMatches(user.uid);
                      // if (matches == true) {
                      //   return null;
                      // }
                      return (
                        <div className="card part_card mx-auto">
                          <div className="cont">
                            <div key={user.uid} className="mini-player">
                              <button
                                className="pin_btn"
                                onClick={() => {
                                  const currentPlayer = players.find(
                                    (p) => p.uid === user.uid
                                  );
                                  return (
                                    props.onClickPin &&
                                    props.onClickPin({
                                      index,
                                      videoTrack: user.videoTrack,
                                      audioTrack: user.audioTrack,
                                      uid: user.uid,
                                      player:
                                        currentPlayer &&
                                        currentPlayer.ref.current,
                                    })
                                  );
                                }}
                              >
                                <Bs.BsFillPinAngleFill />
                              </button>

                              {player && (
                                <MediaPlayer
                                  id={`player-${user.uid}`}
                                  videoTrack={user.videoTrack}
                                  audioTrack={user.audioTrack}
                                  style={{ aspectRatio: "16/9" }}
                                  onPlayerReady={(player) => {
                                    return (
                                      props.onPlayerReady &&
                                      props.onPlayerReady(player)
                                    );
                                  }}
                                  ref={player.ref}
                                  remoteUserId={user.uid}
                                  mainPlayer={true}
                                />
                              )}
                              <button className="btn-cont">
                                {!user.hasAudio ? (
                                  <Bs.BsMicFill />
                                ) : (
                                  <Bs.BsMicMuteFill />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Tab>

                <Tab
                  eventKey="Messages"
                  title={
                    <span style={{ fontSize: "13px" }}>
                      <i style={{ fontSize: "17px", marginRight: "2px" }}>
                        <Si.SiGooglemessages />
                      </i>{" "}
                      Messages
                    </span>
                  }
                  className="tab"
                >
                  <div className="chat_area_left mt-3" id="chat" ref={newMsg}>
                    <div class="chat_info">
                      <h5>Please let us know if you face any issues.</h5>
                      <h6>
                        <Ai.AiOutlineMail />
                        info@eastmansolicitors.com
                      </h6>
                    </div>

                    {/* chat Content */}

                    {userDetails &&
                      Array.isArray(chatMessages) &&
                      chatMessages.length != 0 &&
                      chatMessages.map((message) => {
                        console.log("chat", chatMessages);
                        const currentUser = message.userId == userDetails._id;
                        return currentUser ? (
                          <div class="chat d-flex">
                            <div class="avt">
                              <img
                                src={require("../../assets/avatar2.png")}
                                alt=""
                                className="avatarpng"
                              />
                            </div>
                            <div class="txt">
                              <div className="txt_cont">{message.message}</div>
                            </div>
                          </div>
                        ) : (
                          <div class="chat-right d-flex">
                            <div class="txt">
                              <div className="txt_cont">{message.message}</div>
                            </div>
                            <div class="avt" id="right  new-pro">
                              <img
                                src={require("../../assets/avatar2.png")}
                                alt=""
                                className="avatarpng"
                              />
                            </div>
                          </div>
                        );
                      })}

                    {/* chat content end */}
                  </div>
                  {/* Chat Area end */}
                  <div className="sent-box">
                    <input
                      className="p-3"
                      placeholder="write a message...."
                      onChange={onChangeMessageField}
                      onKeyDown={onPressKeyDown}
                      value={message}
                    />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default ChatSession;
