import React from "react";
import { Row, Container, Form, Button } from "react-bootstrap";
import "./Footer.css";
import * as Fa from "react-icons/fa";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import * as Bi from "react-icons/bi";
import Phone from "../../assets/Outlook-2s5p4lxm.png";
import Fax from "../../assets/Outlook-elrucvly.png";
import Message from "../../assets/Outlook-lvcgej4r.png";
import Location from "../../assets/Outlook-0c453cuy.png";
import Footer1 from "../../assets/Outlook-kuuio24r.png";
import Footer2 from "../../assets/Outlook-3nkwvosz.png";
import Footer3 from "../../assets/Outlook-mys05c5q.png";

function Footer() {
  return (
    <>
      <div className="foo">
        <div className="form-top">
          <Container>
            <a href="/">
              <img
                className="footer-logo"
                src={require("../../assets/Eastman-logo-white.png")}
              />
            </a>
            <Row>
              <div className="col-lg-8 col-md-8 col-sm-8 footer1">
                <Row>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="address">
                      <p className="d-flex">
                        {/* <i>
                          <Fa.FaLocationArrow />
                        </i>{" "} */}
                        <img src={Location} className="ph_" ></img>
                        168A High Street North, East Ham, London E6 2JA
                      </p>
                      <p className="d-flex" >
                        <img src={Phone} className="ph_" ></img>
                        {/* <i>
                          <Bs.BsFillTelephoneFill />
                        </i>{" "} */}
                        0208 912 2280
                      </p>
                      <p className="d-flex">
                        <img src={Fax} className="ph_" ></img>
                        {/* <i>
                          <Bi.BiPaperPlane />
                        </i>{" "} */}
                        0208 912 2289<span>(Fax)</span>
                      </p>
                      <p className="d-flex">
                        <img src={Message} className="ph_" ></img>
                        {/* <i>
                          <Ai.AiFillMail />
                        </i>{" "} */}
                         info@eastmansolicitors.com
                      </p>
                    </div>
                    <div class="f_social d-flex">
                      <i className="fb">
                        <a href="https://www.facebook.com/" target="_blank">
                          <Fa.FaFacebookF />
                        </a>
                      </i>
                      <i className="yt">
                        <a href="https://www.youtube.com/" target="_blank">
                          <Bs.BsYoutube />
                        </a>
                      </i>
                      <i className="insta">
                        <a href="https://www.instagram.com/" target="_blank">
                          <Ai.AiFillInstagram />
                        </a>
                      </i>
                      <i className="twt">
                        <a href="https://www.twitter.com/" target="_blank">
                          <Bs.BsTwitter />
                        </a>
                      </i>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3">
                    <h4 class="footer_sub-head">Information</h4>
                    <ul className="footer_ul">
                      <li className="footer_links">
                        <a href="/">Immigration</a>
                      </li>
                      <li className="footer_links">
                        <a href="/">Family Solicitors</a>
                      </li>
                      <li className="footer_links">
                        <a href="/">Conveyancing</a>
                      </li>
                      <li className="footer_links">
                        <a href="/">Wills & Probates</a>
                      </li>
                      <li className="footer_links">
                        <a href="/">Health Law</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2">
                    <h4 class="footer_sub-head">Support</h4>
                    <ul className="footer_ul">
                      <li className="footer_links">
                        <a href="/">FAQ</a>
                      </li>
                      <li className="footer_links">
                        <a href="/">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </Row>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 footer2">
                <p>Get the freshest news immedietly</p>
                <div className="subscribe d-flex">
                  <input
                    type="mail"
                    name=""
                    id=""
                    placeholder="your mail address here"
                  />
                  <button>subscribe</button>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        <div className="footer-btm">
          <div class="foo_line"></div>
          <Container>
              <div className="d-flex img_footer" >
                <img src={Footer1} className="footer_images" ></img>
                <img src={Footer2} className="footer_images" ></img>
                <img src={Footer3} className="footer_images" ></img>
                </div>
            
            <p>Designed & Developed By Ferns-IT</p>
          
          </Container>
        </div>
      </div>
    </>
  );
}

export default Footer;
